import { getAboutUsText } from "../../../functions/functions";
import env from "../../../functions/env";
import AboutUsComponetInterface from "../../../models/interfaces/AboutUsComponetInterface";

const AboutUsComponent: React.FC<AboutUsComponetInterface> = (props) => {
  return (
    <div className="bg-white">
      <h2
        className={`text-center text-lg sm:text-2xl text-gray-600 mt-6 sm:mt-20 ${
          !props.aboutUsFlag ? props.initialOpacity : ""
        } ${props.aboutUsSectionTrigger ? props.bottomTop2Sec : ""}`}
      >
        About Us
      </h2>
      <div className="relative isolate overflow-hidden from-secondary-50/10">
        <div className="mx-auto max-w-7xl px-6 py-8 sm:py-28 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-1">
            <h1
              className={`max-w-2xl text-3xl font-bold tracking-tight text-gray-800 sm:text-6xl lg:col-span-2 xl:col-auto text-center sm:text-start ${
                !props.aboutUsFlag ? props.initialOpacity : ""
              } ${props.aboutUsSectionTrigger ? props.leftRight2Sec : ""}`}
            >
              Discover Cebu’s <br />
              wonders with us!
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p
                className={`text-base sm:text-lg leading-6 sm:leading-8 text-gray-600 text-center sm:text-start ${
                  !props.aboutUsFlag ? props.initialOpacity : ""
                } ${props.aboutUsSectionTrigger ? props.leftRight3Sec : ""}`}
                ref={props.aboutUsSectionRef}
              >
                {getAboutUsText()}
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <button
                  className={`rounded-full bg-primary-50 px-12 sm:px-14 py-3 sm:py-4 text-base sm:text-lg font-normal sm:font-semibold mx-auto sm:ml-0 text-white shadow-sm hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-200 ${
                    !props.aboutUsFlag ? props.initialOpacity : ""
                  } ${props.aboutUsSectionTrigger ? props.bottomTop3Sec : ""}`}
                  onClick={() => props.linkToSectionClickHandler("services")}
                >
                  Learn more
                </button>
              </div>
            </div>
            <img
              src={`${env.CLOUDINARY_URL}/assets/about_us/cebu_wonders-min_l4mzg6.jpg`}
              alt="Cebu Wonders"
              className={`mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 sm:ml-10 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36 duration-500 ${
                !props.aboutUsFlag ? props.initialOpacity : ""
              } ${props.aboutUsSectionTrigger ? props.rightLeft3Sec : ""}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
