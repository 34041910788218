import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import DisclosureComponentInterface from "../../../models/interfaces/DisclosureComponentInterface";

export default function DisclosureComponent(
  props: DisclosureComponentInterface
) {
  return (
    <div className="w-full -mt-1">
      <div className="mx-auto w-full rounded-b-xl bg-gray-100 shadow-lg">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium text-primary-100 sm:hover:bg-dealogikal-50 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                <span className="text-md py-1 font-semibold leading-6 text-primary-100 group-hover:text-primary-300">
                  {props.headerText}
                </span>
                <ChevronUpIcon
                  className={`${
                    open ? "mt-1 rotate-180 transform" : ""
                  } mt-1 h-5 w-5 text-primary-100`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-5 pt-1 pb-2 text-sm text-gray-500 border">
                {props.body}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
