import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import ContactUsComponentInterface from "../../../models/interfaces/ContactUsComponentInterface";
import { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactUsComponent: React.FC<ContactUsComponentInterface> = (props) => {
  // constants
  // Your EmailJS service ID, template ID, and Public key values
  const serviceID = "service_957sbtl";
  const templateID = "template_9gffcok";
  const publicKey = "j9X8-TOBEm3wxGXh6";

  // flags
  const [sendingInquiryEmail, setSendingInquiryEmail] =
    useState<boolean>(false);
  const [contactFieldsEmptyError, setContactFieldEmptyError] = useState<{
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    phoneNumber: boolean;
    message: boolean;
  }>({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    message: false,
  });

  // local states
  const [txtFirstname, setTxtFirstname] = useState<string>("");
  const [txtLastname, setTxtLastname] = useState<string>("");
  const [txtEmail, setTxtEmail] = useState<string>("");
  const [txtPhoneNumber, setTxtPhoneNumber] = useState<string>("");
  const [txtMessage, setTxtMessage] = useState<string>("");

  const sendEmailInquiryClickHandler = (e: any) => {
    e.preventDefault();
    setSendingInquiryEmail(true);
    resetErrorState();

    if (
      txtFirstname === "" ||
      txtLastname === "" ||
      txtEmail === "" ||
      txtPhoneNumber === "" ||
      txtMessage === ""
    ) {
      setSendingInquiryEmail(false);
      if (txtFirstname === "") {
        setContactFieldEmptyError((prev) => ({ ...prev, firstName: true }));
      }
      if (txtLastname === "") {
        setContactFieldEmptyError((prev) => ({ ...prev, lastName: true }));
      }
      if (txtEmail === "") {
        setContactFieldEmptyError((prev) => ({ ...prev, email: true }));
      }
      if (txtPhoneNumber === "") {
        setContactFieldEmptyError((prev) => ({ ...prev, phoneNumber: true }));
      }
      if (txtMessage === "") {
        setContactFieldEmptyError((prev) => ({ ...prev, message: true }));
      }
    } else {
      // Create a new object that contains dynamic template params
      const templateParams = {
        from_name: txtFirstname + " " + txtLastname,
        from_email: txtEmail,
        from_phoneNumber: txtPhoneNumber,
        to_name: "Suroy Cebu Tour PH",
        message: txtMessage,
      };

      // Send the email using EmailJS
      emailjs
        .send(serviceID, templateID, templateParams, publicKey)
        .then((response) => {
          if (response.status === 200) {
            setSendingInquiryEmail(false);
            clearContactFields();
            alert("Email sent. We will get back to you shortly.");
          }
        })
        .catch((reason) => console.log(reason));
    }
  };

  const clearContactFields = () => {
    setTxtFirstname("");
    setTxtLastname("");
    setTxtEmail("");
    setTxtPhoneNumber("");
    setTxtMessage("");
  };

  const resetErrorState = () => {
    setContactFieldEmptyError({
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      message: false,
    });
  };

  return (
    <div className="relative isolate bg-gray-100">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-8 sm:px-6 pb-1 sm:pb-20 pt-8 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2
              className={`text-3xl sm:text-4xl font-bold tracking-tight text-gray-900 ${
                !props.contactUsFlag ? props.initialOpacity : ""
              } ${props.contactUsSectionTrigger ? props.leftRight2Sec : ""}`}
            >
              Want to contact Suroy Cebu Tour?
            </h2>
            <p
              className={`mt-2 sm:mt-4 text-base sm:text-lg leading-8 text-gray-600 ${
                !props.contactUsFlag ? props.initialOpacity : ""
              } ${props.contactUsSectionTrigger ? props.leftRight2P5Sec : ""}`}
            >
              Here are a few ways to get in touch with us.
            </p>

            <p
              className={`mt-10 text-base leading-8 text-gray-600 font-medium ${
                !props.contactUsFlag ? props.initialOpacity : ""
              } ${props.contactUsSectionTrigger ? props.leftRight3Sec : ""}`}
            >
              Contact us through:
            </p>
            <dl
              className={`mt-6 space-y-4 text-base leading-7 text-gray-600 ${
                !props.contactUsFlag ? props.initialOpacity : ""
              } ${props.contactUsSectionTrigger ? props.leftRight3Sec : ""}`}
              ref={props.contactUsSectionRef}
            >
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <MapPinIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  Canduman, Mandaue City
                  <br />
                  Philippines
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-900"
                    href="tel:+1 (555) 234-5678"
                  >
                    +63 927 055 7006
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-900"
                    href="mailto:hello@example.com"
                  >
                    cebutourphsuroy@gmail.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <form
          action="#"
          method="POST"
          className="pb-24 pt-20 sm:pb-32 lg:py-48"
        >
          <div
            className={`mx-4 sm:mx-auto mt-2 max-w-xl lg:max-w-xl px-6 sm:px-12 py-12 rounded-lg bg-white shadow-md ${
              !props.contactUsFlag ? props.initialOpacity : ""
            } ${props.contactUsSectionTrigger ? props.bottomTop2Sec : ""}`}
          >
            <p className="text-lg leading-8 text-gray-600">
              You can also send your inquires through the awesome field below.
            </p>
            {(contactFieldsEmptyError.firstName ||
              contactFieldsEmptyError.lastName ||
              contactFieldsEmptyError.email ||
              contactFieldsEmptyError.phoneNumber ||
              contactFieldsEmptyError.message) && (
              <div className="text-sm text-red-500 text-center mt-2">
                Please fill in the fields completely.
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-8 gap-y-5 sm:grid-cols-2 mt-6">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    value={txtFirstname}
                    autoComplete="given-name"
                    className={`block w-full rounded-lg border-0 px-3.5 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 ${
                      !contactFieldsEmptyError.firstName
                        ? "ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-primary-50"
                        : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                    }  sm:text-base sm:leading-6`}
                    onChange={(e) => setTxtFirstname(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={txtLastname}
                    autoComplete="family-name"
                    className={`block w-full rounded-lg border-0 px-3.5 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 ${
                      !contactFieldsEmptyError.lastName
                        ? "ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-50"
                        : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                    } sm:text-sm sm:leading-6`}
                    onChange={(e) => setTxtLastname(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={txtEmail}
                    autoComplete="email"
                    className={`block w-full rounded-lg border-0 px-3.5 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 ${
                      !contactFieldsEmptyError.email
                        ? "ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-50"
                        : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                    } sm:text-sm sm:leading-6`}
                    onChange={(e) => setTxtEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Phone number
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    value={txtPhoneNumber}
                    autoComplete="tel"
                    className={`block w-full rounded-lg border-0 px-3.5 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 ${
                      !contactFieldsEmptyError.phoneNumber
                        ? "ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-50"
                        : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                    } sm:text-sm sm:leading-6`}
                    onChange={(e) => setTxtPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={3}
                    value={txtMessage}
                    className={`block w-full rounded-lg border-0 px-3.5 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 ${
                      !contactFieldsEmptyError.message
                        ? "ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-50"
                        : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                    } sm:text-sm sm:leading-6`}
                    onChange={(e) => setTxtMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className={`${
                  sendingInquiryEmail ? "cursor-not-allowed opacity-50" : ""
                } rounded-full bg-primary-50 px-5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-50`}
                onClick={sendEmailInquiryClickHandler}
              >
                {sendingInquiryEmail ? "Sending..." : "Send message"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUsComponent;
