import { ChevronUpIcon } from "@heroicons/react/24/outline";
import BackToTopButtonComponentInterface from "../../../models/interfaces/BackToTopButtonComponentInterface";

const BackToTopButtonComponent: React.FC<BackToTopButtonComponentInterface> = (
  props
) => {
  return (
    <button
      title="Back to top"
      className={`${
        props.isVisible ? "visible" : "invisible"
      } bg-primary-50 px-5 py-2.5 text-base fixed bottom-4 right-4 rounded-t-md text-white shadow-md hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white`}
      onClick={props.backToTopClickHandler}
    >
      <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};
export default BackToTopButtonComponent;
