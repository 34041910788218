import env from "../../../functions/env";
import IteneraryType from "../../../models/types/IteneraryType";

const iteneraryData: IteneraryType[] = [
  {
    iteneraryId: 1,
    title: "CEBU CITY & UPHILL TOUR",
    imageUrl: `${env.CLOUDINARY_URL}/assets/sample_iteneraries/basilica-min_udawyl.jpg`,
    includes: [
      {
        includeId: 1,
        name: "Sirao Flower Farm",
      },
      {
        includeId: 2,
        name: "Little Tokyo",
      },
      {
        includeId: 3,
        name: "Temple of Leah",
      },
      {
        includeId: 4,
        name: "Taoist Temple",
      },
      {
        includeId: 5,
        name: "House of Lechon for Lunch",
      },
      {
        includeId: 6,
        name: "Magellan's Cross",
      },
      {
        includeId: 7,
        name: "Sto. Niño Church",
      },
      {
        includeId: 8,
        name: "CCLEX (Longest Bridge)",
      },
      {
        includeId: 9,
        name: "10,000 Roses (optional)",
      },
    ],
  },
  {
    iteneraryId: 2,
    title: "BADIAN/MOALBOAL TOUR",
    imageUrl: `${env.CLOUDINARY_URL}/assets/sample_iteneraries/kawasan_falls-min_x0tm4c.jpg`,
    includes: [
      {
        includeId: 10,
        name: "Mantayupan Falls (Sigthseeing)",
      },
      {
        includeId: 11,
        name: "Snorkling",
      },
      {
        includeId: 12,
        name: "Sardines Run",
      },
      {
        includeId: 13,
        name: "Turtle Encounter",
      },
      {
        includeId: 14,
        name: "Coral Garden",
      },
      {
        includeId: 15,
        name: "Canyoneering (optional)",
      },
    ],
  },
  {
    iteneraryId: 3,
    title: "OSLOB & SIMALA TOUR",
    imageUrl: `${env.CLOUDINARY_URL}/assets/sample_iteneraries/whale_shark_oslob-min_b09ts2.jpg`,
    includes: [
      {
        includeId: 16,
        name: "Whaleshark Watching",
      },
      {
        includeId: 17,
        name: "Sumilon Island (optional)",
      },
      {
        includeId: 18,
        name: "Tumalog Falls",
      },
      {
        includeId: 19,
        name: "Oslob Heritage Park",
      },
      {
        includeId: 20,
        name: "Simala Church",
      },
      {
        includeId: 21,
        name: "Carcar Lechon",
      },
    ],
  },
];

export default iteneraryData;
