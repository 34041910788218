import Landing from "../pages/landing/Landing";

const PublicRoutes = [
  // {
  //   path: "/explore",
  //   element: <Explore />,
  // },
  {
    path: "/",
    element: <Landing />,
  },
  // {
  //   path: "/loading",
  //   element: <Loading />,
  // },
];

export default PublicRoutes;
