import env from "../../../functions/env";
import ServiceType from "../../../models/types/ServiceType";

const services: ServiceType[] = [
  {
    id: 1,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/car-min_xlpxic.png`,
    text: "Car & Van Rental",
  },
  {
    id: 2,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/destination-min_tuzme5.png`,
    text: "3D2N South Cebu Tours",
  },
  {
    id: 3,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/shuttle-min_b9bh4j.png`,
    text: "Company Shuttle Service",
  },
  {
    id: 4,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/blue-whale-min_a3u0b5.png`,
    text: "Whale Shark + Kawasan Canyoneering Tours",
  },
  {
    id: 5,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/waterfall-min_n54sik.png`,
    text: "Moalboal + Kawasan + Canyoneering",
  },
  {
    id: 6,
    icon: `${env.CLOUDINARY_URL}/assets/New_Icons/island-min_n2k483.png`,
    text: "Whale Shark + Piscador Island Hopping with sardines & turtle chase",
  },
];

export default services;
