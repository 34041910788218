import ServiceType from "../../../models/types/ServiceType";

const ServiceCardComponent: React.FC<ServiceType> = (props) => {
  return (
    <div
      className={`flex flex-col w-full sm:w-1/3 mt-4 duration-300 hover:scale-105`}
    >
      <div className="bg-white shadow-md rounded-xl mx-2 py-4 sm:py-10 hover:shadow-lg duration-300">
        <div className="px-4 py-5 sm:p-6">
          <img
            src={props.icon}
            alt=""
            className="h-16 sm:h-24 w-auto mx-auto"
          />
          <div className="mt-4 text-center">
            <h3 className="text-sm text-primary-100 font-normal sm:font-semibold">
              {props.text}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCardComponent;
