import { BrowserRouter, Routes, Route } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";

// added skeleton here...
import "react-loading-skeleton/dist/skeleton.css";
import PageNotFound from "./pages/page_note_found/PageNotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* render page not found here... */}
          <Route path="*" element={<PageNotFound />} />
          {/* render all public routes here... */}
          {PublicRoutes.map((item, key) => (
            <Route {...item} key={key} />
          ))}
          {/* render all private routes here...
          <Route element={<ProtectedRoutes />}>
            {PrivateRoutes.map((item, key) => (
              <Route {...item} key={key} />
            ))}
          </Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
