interface LoadingCompInterface {
  transition_text?: string;
}

const LoadingComp: React.FC<LoadingCompInterface> = (props) => {
  return (
    <div className="flex flex-wrap justify-center md:px-4 duration-1000">
      <div className="flex flex-col w-full p-2 md:w-2/5 h-screen">
        <div className="my-auto md:pb-28 text-center">
          <div className="mt-4 text-sm md:text-lg text-primary-50 font-semibold">
            {props.transition_text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComp;
