import DestinationComponentInterface from "../../../models/interfaces/DestinationComponentInterface";

const DestinationComponent: React.FC<DestinationComponentInterface> = (
  props
) => {
  const renderDestinationInfo = (imagePlacement: string) => {
    if (imagePlacement === "right") {
      return (
        <div className="mx-auto max-w-7xl px-6 sm:px-8">
          <div className="overflow-hidden bg-white py-8 sm:py-12">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                    <span className="text-sm font-semibold leading-4 text-gray-600 bg-gray-100 px-6 py-1.5 rounded-full">
                      {props.destination.municipality}
                    </span>
                    <p className="mt-5 text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
                      {props.destination.title}
                    </p>
                    <p className="mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600">
                      {props.destination.description}
                    </p>
                    <dl className="mt-10 max-w-xl space-y-4 text-base sm:text-lg leading-5 sm:leading-6 text-gray-600 lg:max-w-none">
                      {props.destination.otherInformation?.map((info: any) => (
                        <div
                          key={info.name + Math.random().toString()}
                          className="relative pl-9"
                        >
                          <dt className="inline font-semibold text-gray-900">
                            <info.icon
                              className="absolute left-1 top-1 h-5 w-5 text-primary-100"
                              aria-hidden="true"
                            />
                            {info.info}:
                          </dt>{" "}
                          <dd className="inline">{info.text}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
                <div className="sm:px-6 lg:px-0 -mt-1 sm:mt-6">
                  <div className="relative isolate overflow-hidden bg-indigo-500 sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none border">
                    <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                      <img
                        src={props.destination.imageUrl}
                        alt="Product screenshot"
                        className="w-full h-auto max-w-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="block sm:hidden" />
        </div>
      );
    } else {
      return (
        <div className="mx-auto max-w-7xl px-6 sm:px-8">
          <div className="overflow-hidden bg-white py-8 sm:py-12">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                {/* for left side pictures, this will be display in desktop screens */}
                <div className="sm:px-6 lg:px-0 mt-6 hidden sm:block">
                  <div className="relative isolate overflow-hidden bg-indigo-500 sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none border">
                    <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                      <img
                        src={props.destination.imageUrl}
                        alt="Product screenshot"
                        className="w-full h-auto max-w-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-6 lg:px-0 lg:pl-4 lg:pt-4 border-red-500">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                    <span className="text-sm font-semibold leading-4 text-gray-600 bg-gray-100 px-6 py-1.5 rounded-full">
                      {props.destination.municipality}
                    </span>
                    <p className="mt-5 text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
                      {props.destination.title}
                    </p>
                    <p className="mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600">
                      {props.destination.description}
                    </p>
                    <dl className="mt-10 max-w-xl space-y-4 text-base sm:text-lg leading-5 sm:leading-6 text-gray-600 lg:max-w-none">
                      {props.destination.otherInformation?.map((info: any) => (
                        <div
                          key={info.name + Math.random().toString()}
                          className="relative pl-9"
                        >
                          <dt className="inline font-semibold text-gray-900">
                            <info.icon
                              className="absolute left-1 top-1 h-5 w-5 text-primary-100"
                              aria-hidden="true"
                            />
                            {info.info}:
                          </dt>{" "}
                          <dd className="inline">{info.text}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
                {/* for left side pictures, this will be hidden in desktop screens */}
                <div className="sm:px-6 lg:px-0 -mt-1 sm:mt-6 block sm:hidden">
                  <div className="relative isolate overflow-hidden bg-indigo-500 sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none border">
                    <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                      <img
                        src={props.destination.imageUrl}
                        alt="Product screenshot"
                        className="w-full h-auto max-w-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <>{renderDestinationInfo(props.imagePlacement)}</>;
};

export default DestinationComponent;
