import { useEffect, useRef, useState } from "react";
import NavbarComponent from "../templates/NavbarComponent/NavbarComponent";
import AboutUsComponent from "../templates/AboutUsComponent/AboutUsComponent";
import ServiceCardComponent from "../templates/ServiceCardComponent/ServiceCardComponent";
import IteneraryComponent from "../templates/IteneraryComponent/IteneraryComponent";
import iteneraryData from "./IteneraryData/IteneraryData";
import ServiceType from "../../models/types/ServiceType";
import destinations from "./PopularDestinationData/PopularDestinationData";
import ContactUsComponent from "../templates/ContactUsComponent/ContactUsComponent";
import FooterComponent from "../templates/FooterComponent/FooterComponent";
import customStyles from "./LandingComp.module.css";
import { useInView } from "react-intersection-observer";
import DestinationComponent from "../templates/DestinationComponent/DestinationComponent";
import DestinationType from "../../models/types/DestinationType";
import services from "./ServicesData/ServicesData";
import navigation from "./NavigationData/NavigationData";
import BackToTopButtonComponent from "../templates/BackToTopButtonComponent/BackToTopButtonComponent";
import LoadingComp from "../loading/LoadingComp";

const LandingComp = () => {
  // flags
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isBackToTopButtonVisible, setIsBackToTopButtonVisible] =
    useState<boolean>(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
  // useEffect hook that will handle splash/loading screen
  useEffect(() => {
    setTimeout(() => {
      setIsContentLoading(false);
    }, 3000);
  }, []);

  // refs
  const heroRef = useRef<any>(null);
  const servicesRef = useRef<any>(null);
  const aboutUsRef = useRef<any>(null);
  const iteneraryRef = useRef<any>(null);
  const destinationRef = useRef<any>(null);
  const contactUsRef = useRef<any>(null);

  // animation flag, trigger, hooks
  // ---> hero section
  const [heroSectionFlag, setHeroSectionFlag] = useState<boolean>(false);
  const { ref: heroSectionRef, inView: heroSectionTrigger } = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setHeroSectionFlag(heroSectionTrigger);
  }, [heroSectionTrigger]);
  // ---> services offered section
  const [serviceSectionFlag, setServiceSectionFlag] = useState<boolean>(false);
  const { ref: serviceSectionRef, inView: serviceSectionTrigger } = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setServiceSectionFlag(serviceSectionTrigger);
  }, [serviceSectionTrigger]);
  // ---> about us section
  const [aboutUsFlag, setAboutUsFlag] = useState<boolean>(false);
  const { ref: aboutUsSectionRef, inView: aboutUsSectionTrigger } = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setAboutUsFlag(aboutUsSectionTrigger);
  }, [aboutUsSectionTrigger]);
  // ---> itenerary section
  const [iteneraryFlag, setIteneraryFlag] = useState<boolean>(false);
  const { ref: itenerarySectionRef, inView: itenerarySectionTrigger } =
    useInView({
      triggerOnce: true,
    });
  useEffect(() => {
    setIteneraryFlag(itenerarySectionTrigger);
  }, [itenerarySectionTrigger]);
  // ---> popular destinations section
  const [popularDestinationFlag, setPopularDestinationFlag] =
    useState<boolean>(false);
  const {
    ref: popularDestinationSectionRef,
    inView: popularDestinationSectionTrigger,
  } = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setPopularDestinationFlag(popularDestinationSectionTrigger);
  }, [popularDestinationSectionTrigger]);
  // ---> contact us section
  const [contactUsFlag, setContactUsFlag] = useState<boolean>(false);
  const { ref: contactUsSectionRef, inView: contactUsSectionTrigger } =
    useInView({
      triggerOnce: true,
    });
  useEffect(() => {
    setContactUsFlag(contactUsSectionTrigger);
  }, [contactUsSectionTrigger]);

  /*
   function click handler to scroll and locate section if:
  
    (1) nav & footer items are clicked
    (2) back to top is being clicked
    (3) get started button is being clicked in the hero section
    (4) learn more button in about us section is being clicked
  
  */
  const linkToSectionClickHandler = (section: string) => {
    switch (section) {
      case "hero":
        heroRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "services":
        servicesRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "about_us":
        aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "itenerary":
        iteneraryRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "destinations":
        destinationRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  /*
    useEffect hook that will handle changes in scrolling / section focus
  */
  // cja: this useEffect hook will identify if the scroll position is back to top
  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is at the top
      const atTop = window.scrollY === 0;

      // Update the state only if the scroll position changes
      if (atTop !== isAtTop) {
        setIsAtTop(atTop);
      } else if (window.scrollY > 2216) {
        setIsBackToTopButtonVisible(true);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAtTop]);

  useEffect(() => {
    if (isAtTop) {
      setIsBackToTopButtonVisible(false);
    }
  }, [isAtTop]);

  const backToTopClickHandler = () => {
    linkToSectionClickHandler("hero");
    setIsBackToTopButtonVisible(false);
  };

  return (
    <>
      {/* appear this splash screen */}
      {isContentLoading && <LoadingComp transition_text="Loading..." />}
      <div
        className={`min-h-screen flex bg-parallax bg-cover bg-fixed bg-no-repeat bg-center duration-1000 ${
          isContentLoading ? "invisible" : "visible"
        }`}
        ref={heroRef}
      >
        <NavbarComponent
          navigationItems={navigation}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          linkToSectionClickHandler={linkToSectionClickHandler}
        />
        <div className="mx-auto py-24 sm:px-6 sm:py-48 lg:px-8">
          <div className="relative py-14 text-center">
            <h2
              className={`mx-auto text-3xl sm:4xl font-bold tracking-tight text-white sm:text-7xl ${
                !heroSectionFlag ? customStyles.opacity_0 : ""
              } ${
                heroSectionTrigger && !isContentLoading
                  ? customStyles.bottomTop_2s
                  : ""
              }`}
            >
              Discover <span className="text-primary-50">Cebu</span> Wonders{" "}
              <span className="text-primary-50">With Us</span>.
            </h2>
            <p
              className={`mx-auto mt-6 sm:mt-12 max-w-xl text-base sm:text-2xl leading-8 text-white italic ${
                !heroSectionFlag ? customStyles.opacity_0 : ""
              } ${
                heroSectionTrigger && !isContentLoading
                  ? customStyles.bottomTop_3s
                  : ""
              }`}
            >
              “Discover Cebu: Where Love and Adventure <br /> Meet on Every
              Shore!”
            </p>
            <p
              className={`mx-auto mt-56 sm:mt-60 max-w-xl text-base sm:text-xl leading-snug text-white font-semibold ${
                !heroSectionFlag ? customStyles.opacity_0 : ""
              } ${
                heroSectionTrigger && !isContentLoading
                  ? customStyles.bottomTop_4s
                  : ""
              }`}
            >
              BOOK AND RESERVE YOUR SLOTS <br /> WITH US NOW!
            </p>
            <div
              className={`mt-6 flex items-center justify-center gap-x-6 ${
                !heroSectionFlag ? customStyles.opacity_0 : ""
              } ${
                heroSectionTrigger && !isContentLoading
                  ? customStyles.bottomTop_4s
                  : ""
              }`}
              ref={heroSectionRef}
            >
              <button
                className="rounded-full bg-primary-50 px-12 sm:px-14 py-3 sm:py-4 text-base sm:text-lg font-normal sm:font-semibold text-white shadow-md hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                onClick={() => linkToSectionClickHandler("about_us")}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
      {!isContentLoading && (
        <>
          {/* Services Offered (Start) */}
          <div
            className="min-h-screen justify-center bg-gray-100 pb-8 sm:pb-0"
            ref={servicesRef}
          >
            <div className="flex flex-wrap justify-center">
              <div className="flex flex-col w-full sm:p-10 md:w-full">
                <h2
                  className={`text-center text-lg sm:text-2xl text-gray-600 mt-6 sm:mt-10 ${
                    !serviceSectionFlag ? customStyles.opacity_0 : ""
                  } ${serviceSectionTrigger ? customStyles.bottomTop_2s : ""}`}
                >
                  Services Offered
                </h2>
              </div>
            </div>

            <div
              className={`flex flex-wrap justify-center mt-2 sm:mt-24 mx-12 sm:mx-32 ${
                !serviceSectionFlag ? customStyles.opacity_0 : ""
              } ${serviceSectionTrigger ? customStyles.bottomTop_4s : ""}`}
              ref={serviceSectionRef}
            >
              {services.map((service: ServiceType) => (
                <ServiceCardComponent
                  key={Math.random().toString() + service.id}
                  id={service.id}
                  text={service.text}
                  icon={service.icon}
                />
              ))}
            </div>
          </div>
          {/* Services Offered (End) */}
          {/* About Us (Start) */}
          <div className="min-h-screen justify-center" ref={aboutUsRef}>
            <div className="flex flex-wrap">
              <div className="flex flex-col w-full md:w-full">
                <AboutUsComponent
                  aboutUsSectionRef={aboutUsSectionRef}
                  aboutUsSectionTrigger={aboutUsSectionTrigger}
                  aboutUsFlag={aboutUsFlag}
                  initialOpacity={customStyles.opacity_0}
                  bottomTop2Sec={customStyles.bottomTop_2s}
                  bottomTop3Sec={customStyles.bottomTop_3s}
                  leftRight2Sec={customStyles.leftRight_2s}
                  leftRight3Sec={customStyles.leftRight_3s}
                  rightLeft3Sec={customStyles.rightLeft_3s}
                  linkToSectionClickHandler={linkToSectionClickHandler}
                />
              </div>
            </div>
          </div>
          {/* About Us (End) */}
          {/* Sample Iteneraries (Start) */}
          <div className="bg-gray-100 py-8 sm:py-32" ref={iteneraryRef}>
            <div
              className="mx-auto max-w-7xl px-6 lg:px-8"
              ref={itenerarySectionRef}
            >
              <div
                className={`mx-auto max-w-2xl text-center ${
                  !iteneraryFlag ? customStyles.opacity_0 : ""
                } ${itenerarySectionTrigger ? customStyles.bottomTop_2s : ""}`}
              >
                <h2
                  className={`text-3xl sm:text-4xl font-bold tracking-tight text-gray-800`}
                >
                  Sample <span className="text-primary-50">Iteneraries</span>
                </h2>
                <p
                  className={`mt-1 sm:mt-2 text-base sm:text-lg leading-8 text-gray-600`}
                >
                  Plan your Cebu journey now with this iteneraries we offer.
                </p>
              </div>
              <div
                className={`flex flex-wrap justify-center w-full mt-8 sm:mt-16 ${
                  !iteneraryFlag ? customStyles.opacity_0 : ""
                } ${itenerarySectionTrigger ? customStyles.bottomTop_3s : ""}`}
              >
                <IteneraryComponent
                  key={Math.random()}
                  iteneraries={iteneraryData}
                />
              </div>
            </div>
          </div>
          {/* Sample Iteneraries (End) */}
          {/* Popular Destinations (Start) */}
          <div className="bg-white py-8 sm:py-34 sm:mt-10" ref={destinationRef}>
            <div className="mx-auto max-w-7xl">
              <div className="mx-auto max-w-2xl lg:max-w-4xl text-center mb-16">
                <h2
                  className={`text-3xl sm:text-4xl font-bold tracking-tight text-gray-800 ${
                    !popularDestinationFlag ? customStyles.opacity_0 : ""
                  } ${
                    popularDestinationSectionTrigger
                      ? customStyles.bottomTop_2s
                      : ""
                  }`}
                >
                  Popular Cebu{" "}
                  <span className="text-primary-50">Destinations</span>
                </h2>
                <p
                  className={`mt-1 sm:mt-2 text-base sm:text-lg leading-8 text-gray-600 ${
                    !popularDestinationFlag ? customStyles.opacity_0 : ""
                  } ${
                    popularDestinationSectionTrigger
                      ? customStyles.bottomTop_3s
                      : ""
                  }`}
                  ref={popularDestinationSectionRef}
                >
                  Witness and experiece the popular tourist spots in Cebu.
                </p>
              </div>
              <div
                className={` ${
                  !popularDestinationFlag ? customStyles.opacity_0 : ""
                } ${
                  popularDestinationSectionTrigger
                    ? customStyles.bottomTop_3s
                    : ""
                }`}
              >
                {destinations.map((destination: DestinationType, i: number) => {
                  return (
                    <DestinationComponent
                      key={destination.id.toString() + Math.random()}
                      imagePlacement={`${(i + 1) % 2 === 0 ? "left" : "right"}`}
                      destination={destination}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* Popular Destinations (Start) */}
          {/* Contact Us (Start) */}
          <div ref={contactUsRef}>
            <ContactUsComponent
              contactUsSectionRef={contactUsSectionRef}
              contactUsSectionTrigger={contactUsSectionTrigger}
              contactUsFlag={contactUsFlag}
              initialOpacity={customStyles.opacity_0}
              leftRight2Sec={customStyles.leftRight_2s}
              leftRight2P5Sec={customStyles.leftRight_2_5s}
              leftRight3Sec={customStyles.leftRight_3s}
              bottomTop2Sec={customStyles.bottomTop_2s}
            />
          </div>
          {/* Contact Us (Start) */}
          {/* Footer (Start) */}
          <FooterComponent
            linkToSectionClickHandler={linkToSectionClickHandler}
          />
          {/* Footer (Start) */}
          <BackToTopButtonComponent
            isVisible={isBackToTopButtonVisible}
            backToTopClickHandler={backToTopClickHandler}
          />
        </>
      )}
    </>
  );
};

export default LandingComp;
