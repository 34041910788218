import { Fragment } from "react";
import IteneraryComponentInterface from "../../../models/interfaces/IteneraryComponentInterface";
import IteneraryType from "../../../models/types/IteneraryType";
import DisclosureComponent from "../DisclosureComponent/DisclosureComponent";

const IteneraryComponent: React.FC<IteneraryComponentInterface> = (props) => {
  return (
    <>
      {props.iteneraries.map((itenerary: IteneraryType) => (
        <div
          key={itenerary.iteneraryId.toString() + Math.random().toString()}
          className="flex flex-col w-full mx-2 md:w-96 mt-10 sm:mt-0"
        >
          <img
            src={itenerary.imageUrl}
            alt=""
            className={`aspect-[16/9] w-full shadow-lg rounded-t-xl sm:rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]`}
          />
          {/* only display in mobile screens */}
          <div className="block sm:hidden">
            <DisclosureComponent
              headerText={itenerary.title}
              body={
                <p className={`text-base leading-10 text-gray-800`}>
                  {itenerary.includes.map((include) => (
                    <Fragment key={include.includeId}>
                      <span>{include.name}</span>
                      <br />
                    </Fragment>
                  ))}
                </p>
              }
            />
          </div>
          {/* only display on desktop screens */}
          <div className="max-w-xl hidden sm:block">
            <div className="group relative">
              <h3
                className={`mt-6 text-xl font-semibold leading-6 text-primary-100 group-hover:text-primary-300 `}
              >
                <span>
                  <span className="absolute inset-0" />
                  {itenerary.title}
                </span>
              </h3>
              <p className={`mt-10 text-lg leading-10 text-gray-800`}>
                {itenerary.includes.map((include) => (
                  <Fragment key={include.includeId}>
                    <span>{include.name}</span>
                    <br />
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default IteneraryComponent;
