import DestinationType from "../../../models/types/DestinationType";
import env from "../../../functions/env";
import {
  ArrowSmallUpIcon,
  ClockIcon,
  MapIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

const destinations: DestinationType[] = [
  {
    id: 1,
    title: "Experience Canyoneering to Kawasan Falls",
    description:
      "Kawasan Falls is a multi-layered waterfall system located in Barangay Matutinao in the town of Badian, Cebu. It is without doubt the most visited tourist attraction in Badian. Famous for its beautiful turquoise water, Kawasan Falls sits at the foot of Mantalongon Mountain Range, approximately one kilometer from the national road of Badian and at least four hours of trekking from Osmeña Peak of Dalaguete. Its cold and clear water cascades through layers of waterfalls emanating from Kandayvic Spring before going to Matutinao River and the Tañon Strait.",
    imageUrl: `${env.CLOUDINARY_URL}/assets/popular_destinations/kawasan-falls-min_ysq3yj.jpg`,
    municipality: "Badian",
    otherInformation: [
      {
        icon: MapPinIcon,
        info: "Address",
        text: "Barangay Matutinao, Badian, Cebu",
      },
      {
        icon: MapIcon,
        info: "Distance",
        text: "103 km from Cebu City",
      },
      {
        icon: ClockIcon,
        info: "Travel",
        text: "3-4 Hours from Cebu City",
      },
      {
        icon: ArrowSmallUpIcon,
        info: "Height",
        text: "40 m (130 ft)",
      },
    ],
  },
  {
    id: 2,
    title: "Oslob Whale Shark Watching",
    description:
      "Whale shark watching activity in Oslob Cebu is undeniably a popular and unique attraction worldwide and yet also controversial. This incredible experience prompts tourists to flock to Oslob shores to encounter these gentle giants. Whale shark watching tours combined with other popular tours in southern Cebu can fill your day with full of fun and fascinating adventures.",
    imageUrl: `${env.CLOUDINARY_URL}/assets/popular_destinations/betanding-min_oion1w.jpg`,
    municipality: "Oslob",
    otherInformation: [
      {
        icon: MapPinIcon,
        info: "Address",
        text: "Natalio Bacalso Avenue, Oslob, 6023 Cebu",
      },
      {
        icon: MapIcon,
        info: "Travel",
        text: "3 hr 50 min (124.9 km) via Natalio B. Bacalso S National Hwy and Natalio Bacalso Avenue",
      },
    ],
  },
  {
    id: 3,
    title: "Basilica Minore Del Sto. Niño: Oldest Catholic Church in Cebu",
    description:
      "Basilica Minore Del Sto. Niño is known as the oldest Catholic Church in Cebu. The church houses the image of the Sto. Niño, the city’s patron saint that represents Jesus as a black Holy Child. It is also a place for prayer and pilgrimage to a large number of people each year. In 1565, Miguel Lopez de Legazpi called Basilica Minore Del Sto. Niño as San Agustin Church. The exact location of the church is the same spot where Legazpi’s Spaniard troupe discovered the image of Sto. Niño. It was thought to be the gift of Ferdinand Magellan to Queen Juana, the wife of Rajah Humabon, as a sign of their allegiance and over forty years after their baptism to Christianity.",
    imageUrl: `${env.CLOUDINARY_URL}/assets/popular_destinations/basilica-min_macrpo.jpg`,
    municipality: "Cebu City",
    otherInformation: [
      {
        icon: MapPinIcon,
        info: "Address",
        text: "Pilgrim’s Center, Osmeña Blvd, Cebu City, 6000 Cebu",
      },
      {
        icon: MapIcon,
        info: "Distance",
        text: "9 km from Mactan-Cebu Airport",
      },
      {
        icon: ClockIcon,
        info: "Hours",
        text: "Everyday @ 6 AM - 7 PM",
      },
    ],
  },
  {
    id: 4,
    title: "Witness the Grandeur of the Temple of Leah",
    description: `If you’re looking for a breathtaking cultural and historical site to add to your bucket list, look no further than Cebu City’s Temple of Leah. Dubbed as the “Taj Mahal” of Cebu, this magnificent temple is more than just a tourist attraction. It’s a testament to a great love that transcends even death. Built by Teodorico Soriano Adarna in honor of his late wife Leah Albino-Adarna, the Temple of Leah is a stunning mausoleum that embodies the Adarna family’s legacy of love, art, and culture. With its grandiose architecture, intricate details, and diverse collection of art and artifacts, the temple is a feast for the senses and a tribute to one of the greatest love stories in the Philippines.`,
    imageUrl: `${env.CLOUDINARY_URL}/assets/popular_destinations/girl-at-temple-min_dkwnht.jpg`,
    municipality: "Busay",
    otherInformation: [
      {
        icon: MapPinIcon,
        info: "Address",
        text: "Cebu Transcentral Hwy, Cebu City, 6000 Cebu",
      },
      {
        icon: MapIcon,
        info: "Travel",
        text: "36 min (10.6 km) via Cebu Transcentral Hwy",
      },
      {
        icon: ClockIcon,
        info: "Hours",
        text: "Everyday @ 6 AM - 11 PM",
      },
    ],
  },
];

export default destinations;
